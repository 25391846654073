import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHandHoldingDollar, faHandshakeSimple, faBriefcase} from "@fortawesome/free-solid-svg-icons"
import useSWR from "swr";
import {graphqlFetcher} from "../modules/swrFetchers";
import {DateTime} from "luxon";
import queryString from "query-string";
import {getArticleUrl} from "../modules/utils";
import parse from "html-react-parser";
import LoadingIndicator from "../components/loadingIndicator";

const IndexPage = () => {
    const {t} = useTranslation();
    const {language, languages, originalPath} = useI18next();

    const [newsList, setNewsList] = useState([]); // news + event sorted by date desc

    const pageGraphQL = `
    query GetPostsEdges($categoryName1: String!, $categoryName2: String!) {
      news: posts(where: {categoryName: $categoryName1}, first: 3) {
        edges {
          node {
            title
            date
            id
          }
        }
      },
      events: posts(where: {categoryName: $categoryName2}, first: 3) {
        edges {
          node {
            title
            date
            id
          }
        }
      }
    }
    `
    const pageGraphQLVar = {
        categoryName1: `news-${language}`,
        categoryName2: `event-highlights-${language}`,
    }
    const { data, error } = useSWR([pageGraphQL, pageGraphQLVar], graphqlFetcher)

    /**
     * Object Array for generating the "Support loving kids section"
     * @type {[{icon: IconDefinition, text: string, url: string}]}
     */
    const supportLovingKidsList = [
        {
            icon: faBriefcase,
            text: t('joinUs'),
            url: "/join-us"
        },
        {
            icon: faHandshakeSimple,
            text: t('volunteerService'),
            url: "/volunteer"
        },
        {
            icon: faHandHoldingDollar,
            text: t('donation'),
            url: "/donation"
        },
    ]

    /**
     * Object Array for generating the "Courses" section
     * @type {[{featured: boolean, title: string, descriptions: string[], url: string}]}
     */
    const courseBrief = [
        {
            title: t('tutoringClass'),
            descriptions: [
                t('privateTutor'),
                t('chineseClass'),
                t('englishClass'),
                t('mathClass'),
                t('phonicsClass'),
                t('groupEngTutor'),
            ],
            url: "/courses/tutoring-classes",
            featured: false,
        },
        {
            title: t('ascp'),
            descriptions: [
                t('normalAscp'),
                t('senAscp'),
            ],
            url: "/courses/ascp",
            featured: true,
        },
        {
            title: t('interestClasses'),
            descriptions: [
                t('taekwondo'),
                t('goChess'),
                t('childrenArtClass'),
                t('childrenSkatingClass'),
            ],
            url: "/courses/interest-classes",
            featured: false,
        }
    ]

    useEffect(() => {
        if (data?.news?.edges[0]?.node?.id || data?.events?.edges[0]?.node?.id) {
            let tmpNewsList = []
            let tmpEventList = []
            let finalList = []

            if (data?.news?.edges[0]?.node?.id) { // extract node object
                tmpNewsList = data.news.edges.map(e => {
                    return {
                        ...e.node,
                        url: getArticleUrl("news", e.node.id)
                    };
                })
            }

            if (data?.events?.edges[0]?.node?.id) { // extract node object
                tmpEventList = data.events.edges.map(e => {
                    return {
                        ...e.node,
                        url: getArticleUrl("event-highlights", e.node.id)
                    }
                })
            }

            // do sorting
            if (tmpNewsList.length > 0 && tmpEventList.length > 0) {
                const tmpFinalList = [...tmpNewsList, ...tmpEventList];
                finalList = tmpFinalList.sort(function({date: d1}, {date: d2}) { // sort by date desc
                    return DateTime.fromISO(d2).toMillis() - DateTime.fromISO(d1).toMillis()
                })
                finalList = finalList.slice(0, 3); // take first 3 elements
            }
            else {
                if (tmpNewsList.length > 0) {
                    finalList = tmpNewsList
                }
                else if (tmpEventList.length > 0) {
                    finalList = tmpEventList
                }
                else {
                    finalList = []
                }
            }

            setNewsList(x => [...finalList]); // set to state
            // setIsError(false);
        }
        else {
            //     if (data) {
            //         setIsError(true)
            //     }
            setNewsList(x => []); // initialize an empty array
        }

    }, [data])

    return (
        <Layout>
            <SEO title={t('homePage')}/>
            {/*======= Hero Section =======*/}
            <section id="hero" className="d-flex align-items-center">

                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <div className="row">
                        <div className="col-xl-6">
                            <h1>
                                {t('loveChildren')}<br/>
                                {t('loveFamily')}<br/>
                                {t('loveYourself')}<br/>
                            </h1>
                            <h2>{t('teachWithLove')}</h2>
                            <a href="#main" className="btn-get-started scrollto">{t('learnMore')}</a>
                        </div>
                    </div>
                </div>

            </section>
            {/*End Hero*/}

            <main id={"main"}>
                {/*======= News Section =======*/}
                <section id="news" className="blog">
                    <div className="container" data-aos="zoom-in">
                        {/*<h3 className={"py-2"}>{t('news')}</h3>*/}
                        <div className="section-title">
                            <h2>{t('news')}</h2>
                        </div>
                        <div className={"row"} data-aos="zoom-out" data-aos-delay="100">
                            {!data && !error && (
                                <>
                                    <LoadingIndicator />
                                </>
                            )}
                            {data && Array.isArray(newsList) && newsList.length === 0 && (
                                <div className={"text-center"}>
                                    {t('noNewsAvail')}
                                </div>
                            )}

                            {newsList.map((e, i)=> {
                                return (
                                    <div className="col-12 col-md-6 col-lg-4 entries" key={e.id}>
                                        <article className="entry">

                                            {/*<div className="entry-img">*/}
                                            {/*    <img src="/assets/img/blog/blog-1.jpg" alt="" className="img-fluid"/>*/}
                                            {/*</div>*/}

                                            <h4 className="entry-title-dark-smaller">
                                                {/*<a href="blog-single.html">Dolorum optio tempore voluptas dignissimos cumque*/}
                                                {/*    fuga qui quibusdam quia</a>*/}
                                                <Link to={e.url}>{parse(e.title)}</Link>
                                            </h4>

                                        </article>
                                    </div>
                                )
                            })}
                        </div>

                        <div className={"row"} data-aos="zoom-out" data-aos-delay="100">
                            <Link to="/media" className="text-end">
                                {t('learnMore')}
                                <i className="bi bi-chevron-double-right"></i>
                            </Link>
                        </div>

                    </div>
                </section>
                {/*End News Section*/}

                {/*======= Courses Section =======*/}
                <section id="courses" className={"pricing section-bg"}>
                    <div className="container" data-aos="fade-up" >
                        {/*<h3 className={"py-2"}>{t('news')}</h3>*/}
                        <div className="section-title">
                            <h2>{t('courses')}</h2>
                        </div>

                        <div className={"row"} >
                            {courseBrief.map((x, i) => {
                                return (
                                    <div className="col-lg-4 col-md-6 my-1 my-md-0" key={i}>
                                        <div className={`box ${x.featured ? "featured" : ""}`} data-aos="fade-up" data-aos-delay="100">
                                            <h3>{x.title}</h3>
                                            {/*<h4><sup>$</sup>0<span> / month</span></h4>*/}
                                            <ul>
                                                {x.descriptions.map((k, ii) => {
                                                    return (
                                                        <li key={ii}>
                                                            {k}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <div className="btn-wrap">
                                                <Link to={x.url} className="btn-buy">{t('learnMore')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/*<div className="col-lg-4 col-md-6">*/}
                            {/*    <div className="box" data-aos="fade-up" data-aos-delay="100">*/}
                            {/*        <h3>Free</h3>*/}
                            {/*        <h4><sup>$</sup>0<span> / month</span></h4>*/}
                            {/*        <ul>*/}
                            {/*            <li>Aida dere</li>*/}
                            {/*            <li>Nec feugiat nisl</li>*/}
                            {/*            <li>Nulla at volutpat dola</li>*/}
                            {/*            <li className="na">Pharetra massa</li>*/}
                            {/*            <li className="na">Massa ultricies mi</li>*/}
                            {/*        </ul>*/}
                            {/*        <div className="btn-wrap">*/}
                            {/*            <a href="#" className="btn-buy">Buy Now</a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-lg-4 col-md-6 mt-4 mt-md-0">*/}
                            {/*    <div className="box featured" data-aos="fade-up" data-aos-delay="200">*/}
                            {/*        <h3>Business</h3>*/}
                            {/*        <h4><sup>$</sup>19<span> / month</span></h4>*/}
                            {/*        <ul>*/}
                            {/*            <li>Aida dere</li>*/}
                            {/*            <li>Nec feugiat nisl</li>*/}
                            {/*            <li>Nulla at volutpat dola</li>*/}
                            {/*            <li>Pharetra massa</li>*/}
                            {/*            <li className="na">Massa ultricies mi</li>*/}
                            {/*        </ul>*/}
                            {/*        <div className="btn-wrap">*/}
                            {/*            <a href="#" className="btn-buy">Buy Now</a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-lg-4 col-md-6 mt-4 mt-lg-0">*/}
                            {/*    <div className="box" data-aos="fade-up" data-aos-delay="300">*/}
                            {/*        <h3>Developer</h3>*/}
                            {/*        <h4><sup>$</sup>29<span> / month</span></h4>*/}
                            {/*        <ul>*/}
                            {/*            <li>Aida dere</li>*/}
                            {/*            <li>Nec feugiat nisl</li>*/}
                            {/*            <li>Nulla at volutpat dola</li>*/}
                            {/*            <li>Pharetra massa</li>*/}
                            {/*            <li>Massa ultricies mi</li>*/}
                            {/*        </ul>*/}
                            {/*        <div className="btn-wrap">*/}
                            {/*            <a href="#" className="btn-buy">Buy Now</a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>


                    </div>
                </section>
                {/*End Courses Section*/}

                <section id="support-us" className={"tabs"}>
                    <div className="container" data-aos="fade-up" >
                        {/*<h3 className={"py-2"}>{t('news')}</h3>*/}
                        <div className="section-title">
                            <h2>{t('supportLovingKids')}</h2>
                        </div>

                        <div className="row">
                            {supportLovingKidsList.map((e, index) => {
                                return (
                                    <div className="content col-12 col-md-4 d-flex align-items-stretch my-3 my-md-0"
                                         key={index}
                                    >
                                        <div className="content text-center mx-auto px-5 py-4 hover-highlight">
                                            <Link to={e.url}>
                                                <FontAwesomeIcon icon={e.icon} size={"7x"}/>
                                                <h3 className={"mt-4"}>{e.text}</h3>
                                            </Link>

                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;